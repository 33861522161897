
import { Box, Typography } from '@mui/material';

const DividerWithText = ({ text }: { text: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        my: 1,
        width: '80%',
        margin: '0 auto',
      }}
    >
      <Box sx={{ flexGrow: 1, height: '1px', bgcolor: 'grey.400' }} />
      <Box sx={{ mx: 1 }}>
        <Typography variant="subtitle1" color="grey.600">
          {text}
        </Typography>
      </Box>
      <Box sx={{ flexGrow: 1, height: '1px', bgcolor: 'grey.400' }} />
    </Box>
  );
};

export default DividerWithText;
