'use client';
import Image from 'next/image';

import { Box, styled } from '@mui/material';

import BazaarCard from 'libs/components/BazaarCard';

export const StyledProductCardWrapper = styled(BazaarCard)(({ theme }) => ({
  height: 224,
  width: 398,
  [theme.breakpoints.down(850)]: {
    maxWidth: 352,
    maxHeight: 200,
  },
  [theme.breakpoints.down(420)]: {
    maxWidth: 380,
    maxHeight: 228,
  },
  [theme.breakpoints.down(400)]: {
    maxWidth: 360,
    maxHeight: 228,
  },
  [theme.breakpoints.down(380)]: {
    maxWidth: 320,
    maxHeight: 178,
  },
}));

export const StyledProductCardImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'product-image',
})(({ theme }) => ({
  height: 224,
  width: 398,
  [theme.breakpoints.down(850)]: {
    maxWidth: 352,
    maxHeight: 200,
  },
  [theme.breakpoints.down(420)]: {
    maxWidth: 380,
    maxHeight: 228,
  },
  [theme.breakpoints.down(400)]: {
    maxWidth: 360,
    maxHeight: 228,
  },
  [theme.breakpoints.down(380)]: {
    maxWidth: 320,
    maxHeight: 178,
  },
  objectFit: 'cover',
  aspectRatio: '16 / 9',
}));

export const StyledVideoProductVideo = styled('video')(({ theme }) => ({
  height: 224,
  width: 398,
  [theme.breakpoints.down(850)]: {
    maxWidth: 352,
    maxHeight: 200,
  },
  [theme.breakpoints.down(420)]: {
    maxWidth: 380,
    maxHeight: 228,
  },
  [theme.breakpoints.down(400)]: {
    maxWidth: 360,
    maxHeight: 228,
  },
  [theme.breakpoints.down(380)]: {
    maxWidth: 320,
    maxHeight: 178,
  },
  objectFit: 'cover',
  aspectRatio: '16 / 9',
  display: 'none',
}));

export const StyledOverFlowBox = styled(Box)(() => ({
  display: 'flex',
  gap: 16,
  overflow: 'scroll',
  paddingLeft: '10px',
  width: '95%',
  position: 'relative',
}));

export const StyledBazarCard = styled(BazaarCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  mt: 1.5,
  height: 385,
  width: 398,
  [theme.breakpoints.down(850)]: {
    maxWidth: 352,
    maxHeight: 356,
  },
  [theme.breakpoints.down(420)]: {
    maxWidth: 380,
    maxHeight: 383,
  },
  [theme.breakpoints.down(400)]: {
    maxWidth: 360,
    maxHeight: 382,
  },
  [theme.breakpoints.down(380)]: {
    maxWidth: 320,
    maxHeight: 332,
  },
  '::after': {
    content: '""',
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: '60px',
    height: '50px',
    zIndex: '2',
    background:
			'linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.8))',
  },
}));

export const ItemFreeText = styled('div')(() => ({
  background: 'linear-gradient(90deg, #4A3BBF 17.08%, #FF2C84 100%)',
  backgroundClip: 'text',
  fontSize: '16px',
  WebkitBackgroundClip: 'text',
  fontWeight: 600,
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
}));

export const StyledTitle = styled('h2')(({ theme }) => ({
  overflow: 'hidden',
  color: '#404040',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 1,
  fontSize: 16,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'capitalize',
  width: '90%',
  [theme.breakpoints.down('md')]: {
    fontSize: 14,
  },
}));

export const StyledDescription = styled('p')(({ theme }) => ({
  overflow: 'hidden',
  color: '#404040',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  textTransform: 'capitalize',
  [theme.breakpoints.down('md')]: {
    fontSize: 11,
  },
}));
