'use client';

import { Box, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';

export const HorizontallyScollableBox = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'nowrap',
  overflow: 'scroll',
  width: '100%',
  position: 'relative',
  // "::after": {
  // 	content: '""',
  // 	position: "absolute",
  // 	top: 0,
  // 	right: 0,
  // 	bottom: 0,
  // 	width: "50px",
  // 	background:
  // 		"linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.5))",
  // },
  '::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
}));

export const StyledProductPageVideo = styled('video')(() => ({
  height: '450px',
  width: '100%',
  objectFit: 'cover',
  aspectRatio: '16 / 9',
  display: 'block',
  '@media (max-width: 380px)': {
    height: '300px',
  },
}));

export const StyledProductPageImages = styled('img')(() => ({
  height: '450px',
  width: '100%',
  objectFit: 'cover',
  aspectRatio: '16 / 9',
  display: 'block',
  '@media (max-width: 420px)': {
    height: '300px',
  },
}));

export const StyledReactPlayerContainer = styled('div')(() => ({
  position: 'relative',
  width: 800,
  height: 450,
  overflow: 'hidden',
  marginTop: 16,
  '@media (max-width: 600px)': {
    width: 'calc(100vw - 30px)',
    height: 'calc((100vw * 0.5625) - 17px)',
    marginTop: 8,
  },
}));


export const SidebarContainer = styled(Grid)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  alignSelf: 'flex-start',
  mt: 2,
  [theme.breakpoints.down(960)]: {
    position: 'none',
    display: 'none',
  },
}));

export const ContentBox = styled(Box)({
  width: '100%',
  border: '0.5px solid #B4B4B4',
  background: 'white',
});

export const InnerBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px 24px 10px 17px',
}));

export const ActionButton = styled(Button)(() => ({
  width: 260,
}));

export const SimilarTemplatesContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const GridTemplates = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  '@media (max-width: 420px)': {
    display: 'none',
  },
}));

export const FixedBottomBox = styled(Box)(({ theme }: any) => ({
  left: 0,
  right: 0,
  bottom: 0,
  display: 'none',
  position: 'fixed',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: theme.zIndex.drawer - 1,
  height: '65px',
  padding: '16px',
  gap: '16px',
  backgroundColor: 'white',
  boxShadow: '0px 1px 4px 3px rgba(0, 0, 0, 0.1)',
  '@media only screen and (max-width: 960px)': {
    display: 'flex',
    width: '100vw',
  },
}));



export const OffersContainer = styled(Box)(() => ({
  minHeight:'140px',
  maxHeight:'160px',
  width:'100%',
  marginTop: '20px',
  borderRadius: 6,
  border: '0.5px solid #B4B4B4',
  background: '#FFF',
  boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.15)',
  overflowX:'hidden',
}));

export const ExtraOfferBox = styled('div')(()=>({
  display:'flex',
  padding:'10px',
  alignItems:'center',
  minWidth:'220px',
  gap:'10px',
  borderRadius: 6,
  border: '1px dashed #1D5BBE',
  background: '#FFF',
  marginLeft:'20px',
  cursor:'pointer'
}));


