import { Typography } from '@mui/material';

import { useCountDown } from 'hooks/useCountDown';
import { FlexBox } from 'libs/components/flex-box';
import { TimerNumber } from './styles';

export default function RateLimiter () {
  const { timer, displayTimer } = useCountDown();

  return (
    <FlexBox
      alignItems={'center'}
      justifyContent={'center'}
      zIndex={60}
      color={'white'}
      display={displayTimer ? 'flex' : 'none'}
    >
      {/* <FlexBox
        width={'90%'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography variant="h6" display={'flex'} alignItems={'center'}>
					You can continue downloading templates after{' '}
          <AnimatedTooltip
            animationData={RightArrow}
            loop
            style={{
              height: '60px',
              width: '60px',
              transform: 'rotate(270deg)',
            }}
          />
        </Typography> */}

      <FlexBox
        alignItems={'center'}
        sx={{ background: 'white', color: '#5D4CC6' }}
        padding={'0.4rem'}
        gap={'0.2rem'}
      >
        {timer?.map((item: { time: number; text: string }, index: number) => (
          <>
            <FlexBox
              gap={'0.2rem'}
              flexDirection={'column'}
              justifyContent={'center'}
              key={item.text}
            >
              <FlexBox gap={'0.2rem'}>
                {item.time
                  .toString()
                  .padStart(2, '0')
                  .split('')
                  .map((number, index) => (
                    <TimerNumber key={index + number + item.text}>
                      {number}
                    </TimerNumber>
                  ))}
              </FlexBox>
              <Typography variant="subtitle2" textAlign={'center'}>
                {item.text}
              </Typography>
            </FlexBox>
            {index < timer.length - 1 && (
              <Typography variant="h5" textAlign={'initial'} height={'58px'}>
								:
              </Typography>
            )}
          </>
        ))}
      </FlexBox>
      {/* </FlexBox> */}
    </FlexBox>
  );
}
