import Link from 'next/link';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, CircularProgress } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { FlexBox } from 'libs/components/flex-box';
import Eligible from 'libs/components/icons/product/eligible-to-download';
import LimitExhausted from 'libs/components/icons/product/limit-exhauste';
import RateLimiter from 'libs/components/mega-menu/rate-limiter';
import RadioGroupOption from 'libs/components/radio-group-with-child';
import { Small } from 'libs/components/typography';
import { Item } from 'libs/dt-types/response-types/single-item';
import { ActionButton, InnerBox } from './styles';
interface Props {
  currency: string;
  symbol: string;
  price: string;
  discountPrice: string;
  limitExceededText?: string;
  isExhausted?: boolean;
  downloadFile: (_value: boolean) => void;
  isDownloadLoading: boolean;
  item: Item;
  userType: 'FREE' | 'PRO' | 'PREMIUM';
  isLoading?: boolean;
}

const DownloadSection = ({
  symbol,
  price,
  discountPrice,
  limitExceededText,
  isExhausted,
  downloadFile,
  isDownloadLoading,
  isLoading,
  currency,
  item,
  userType,
}: Props) => {
  const userItemFree = userType === 'FREE' && item.type === 'FREE';
  const showLicenseBtn = userItemFree && !isExhausted && !isDownloadLoading;

  return (
    <InnerBox sx={{ textAlign: 'center' }}>
      {!limitExceededText && <RadioGroupOption />}
      {limitExceededText && (
        <>
          {isExhausted && (
            <LimitExhausted
              sx={{ height: '40px', width: '40px', marginBottom: '8px' }}
            />
          )}
          {!isExhausted && (
            <Eligible
              sx={{ height: '40px', width: '40px', marginBottom: '8px' }}
            />
          )}
          <Small
            sx={{
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              textTransform: 'capitalize',
              py: 1,
            }}
          >
            {limitExceededText}
            <a href="/terms-of-use#unlimited-download-policy">
              <InfoOutlinedIcon
                fontSize="inherit"
                sx={{ marginLeft: '0.1rem', cursor: 'pointer' }}
              />
            </a>
          </Small>
        </>
      )}
      <Box py={1}>
        {isLoading ? (
          <Skeleton variant="rectangular" width={240}>
            <CircularProgress size={'30px'} />
          </Skeleton>
        ) : (
          <FlexBox
            position="relative"
            height="fit-content"
            alignItems="center"
            gap="0.5rem"
            flexDirection={'column'}
          >
            {(userType === 'PREMIUM' ||
              item.type.toLowerCase() === userType.toLowerCase()) && (
              <RateLimiter />
            )}
            <FlexBox justifyContent={'center'} gap={1} flexWrap={'wrap'}>
              <ActionButton
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => downloadFile(false)}
                disabled={isDownloadLoading}
                sx={{
                  width: showLicenseBtn ? 140 : 260,
                }}
              >
                {
                  <>
                    {isDownloadLoading ? (
                      <>
                        <CircularProgress size={'20px'} />
                        Downloading...
                      </>
                    ) : isExhausted ? (
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          fontWeight: 600,
                        }}
                      >
                        Buy Now at {''}
                        <del>
                          {symbol}
                          {price}
                        </del>{' '}
                        {symbol}
                        {discountPrice}
                        {currency}
                      </Typography>
                    ) : (
                      'Download'
                    )}
                  </>
                }
              </ActionButton>
              {showLicenseBtn && (
                <ActionButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => downloadFile(true)}
                  disabled={isDownloadLoading}
                  sx={{ width: userItemFree ? 200 : 260 }}
                >
                  Get License @{symbol}
                  {discountPrice}
                  {currency}
                </ActionButton>
              )}
            </FlexBox>
            {(item.type !== 'FREE' || isExhausted) && (
              <Small
                sx={{
                  fontSize: '11px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  textTransform: 'capitalize',
                  textAlign: 'center',
                }}
              >
                License Included
                <a href="/licence">
                  <InfoOutlinedIcon
                    fontSize="inherit"
                    sx={{ marginLeft: '0.3rem', cursor: 'pointer' }}
                  />
                </a>
              </Small>
            )}
          </FlexBox>
        )}
      </Box>
      {userType !== 'PREMIUM' && (
        <Small
          sx={{
            fontSize: '11px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            textTransform: 'capitalize',
            py: 1,
          }}
        >
          Get Unlimited Content With Commercial Licence{' '}
          <Link href="/licence" style={{ textDecoration: 'underline' }}>
            Learn more
          </Link>
        </Small>
      )}
      <Typography
        sx={{
          fontSize: '11px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          textTransform: 'capitalize',
          textAlign: 'center',
          marginTop: '0.5rem',
          color: '#65E',
        }}
      >
        Please ensure pop-ups are enabled to download the file successfully.
      </Typography>
    </InnerBox>
  );
};

export default DownloadSection;
