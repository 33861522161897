'use client';
import React from 'react';

import Cookies from 'js-cookie';

import { DialogDrawer } from 'libs/components/dialog-drawer';
import { Item } from 'libs/dt-types/response-types/single-item';
import ProductPageSidebar from 'page/product/sections/sidebar';

interface Props {
	item: Item;
	userType: 'FREE' | 'PRO' | 'PREMIUM';
	protemplates?: Array<Item>;
	downloadEligiblity: boolean;
	downloadFile: (
		_price: string,
		_currency: string,
	) => void;
	isDownloadLoading: boolean;
	userMessage: string;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	open: boolean;
}

const ProductPurchasePopup = ({
  item,
  userType,
  downloadEligiblity,
  downloadFile,
  isDownloadLoading,
  userMessage,
  open,
  setOpen,
}: Props) => {
  const planDetails = JSON.parse(Cookies.get('planDetails') ?? '{}');

  return (
    <DialogDrawer
      dialogOpen={open}
      toggleDialog={() => {
        setOpen(false);
      }}
      closeBtn
      wrapperStyle={{ width: '420px' }}
    >
      <ProductPageSidebar
        item={item}
        userType={userType ?? 'FREE'}
        downloadEligiblity={downloadEligiblity}
        downloadFile={downloadFile}
        isDownloadLoading={isDownloadLoading}
        userMessage={userMessage}
        planDetails={planDetails}
      />
    </DialogDrawer>
  );
};

export default ProductPurchasePopup;
