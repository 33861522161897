import { cache } from 'react';

import { requestor } from 'libs/helpers/api/requestor';

export const getCollectionList = cache(async (id: string) => {
  try {
    const response = await requestor.get<any>(
      `/collection/collection-items?itemId=${id}`
    );


    return response.data;
  } catch (error) {
    console.error('Error fetching CollectionList:', error);
    throw error;
  }
});
export const getCollectionNames = cache(async () => {
  try {
    const response = await requestor.get<any>('/collection/name');


    return response.data;
  } catch (error) {
    console.error('Error fetching CollectionNames:', error);
    throw error;
  }
});

export const deleteSingleCollection = cache(
  async (collectionId: number, id: string) => {
    try {
      const response = await requestor.delete<any>(
        `/collection/collection-items?collectionId=${collectionId}&itemId=${id}`
      );


      return response.data;
    } catch (error) {
      console.error('Error Deleting Collection!..:', error);
      throw error;
    }
  }
);

export const saveToExistingCollection = cache(
  async (collectionId: string, id: string) => {
    try {
      const response = await requestor.post<any>(
        '/collection/collection-items',
        {
          ['collection_id']: collectionId,
          ['item_id']: id,
        }
      );


      return response.data;
    } catch (error) {
      console.error('Error Deleting Collection!..:', error);
      throw error;
    }
  }
);
